import './bootstrap'
import '../css/app.css'
import 'vue3-toastify/dist/index.css'

import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
        app.use(plugin)
            .use(ZiggyVue)
            .use(Vue3Toastify, {
                autoClose: 5000,
                position: 'top-center',
                transition: 'slide',
                pauseOnHover: false,
                hideProgressBar: true
            } as ToastContainerOptions)
            .mount(el)

        return app
    },
    progress: {
        color: '#4B5563'
    }
})
